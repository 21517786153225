import { SidebarHeader } from '@/components/Sidebar';
import { ProxyOrSudoSidebarAlert } from '@/components/pages/Dashboard/ManageAppchain/ProxyOrSudoSidebarAlert';
import {
  type ManageAppchainTab,
  getTabInfo,
} from '@/components/pages/Dashboard/ManageAppchain/state/ManageAppchain.constants';

interface Props {
  tab: ManageAppchainTab;
  children: React.ReactNode;
  goToOnProxyDisconnect: ManageAppchainTab;
  goBack?: ManageAppchainTab;
}

export function SudoSidebarWrapper({
  tab,
  children,
  goBack,
  goToOnProxyDisconnect,
}: Props) {
  return (
    <>
      <SidebarHeader
        title={getTabInfo(tab).title}
        goBack={goBack}
        goToOnProxyDisconnect={goToOnProxyDisconnect}
        showProxy
      />
      <ProxyOrSudoSidebarAlert />
      {children}
    </>
  );
}
