import { hexToU8a, isHex, isString } from '@polkadot/util';
import { decodeAddress, encodeAddress } from '@polkadot/util-crypto';
import { z } from 'zod';

export function isValidPolkadotAddress(address: string): boolean {
  try {
    encodeAddress(isHex(address) ? hexToU8a(address) : decodeAddress(address));

    return true;
  } catch (_error) {
    return false;
  }
}

export const ethereumAddressSchema = z.string().regex(/^0x[a-fA-F0-9]{40}$/, {
  message: 'Invalid address. Must be 0x-prefixed 20 bytes hex string.',
});

export const substrateAddressSchema = z.custom<string>((val) => {
  return isString(val) && isValidPolkadotAddress(val as string);
}, 'Invalid address. Must be a valid Substrate address.');
