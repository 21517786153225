import type { ChainKey } from '@/config';
import { trpc } from '@/utils/trpc';

export function useRegisteredAppchains(chainKey: ChainKey | undefined) {
  return trpc.appchains.getRegisteredAppchains.useQuery(
    { chainKey: chainKey as ChainKey },
    {
      enabled: !!chainKey,
    },
  );
}
