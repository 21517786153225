import {
  useCreateAppChainFiles,
  useCreateAppChainInfo,
} from '@/components/pages/CreateAppChain/state/create.hooks';
import { useChainConfig } from '@/hooks/useChainConfig';
import { useDownloadedFiles } from '@/hooks/useDownloadedFiles';
import type { Files } from '@/server/router/file/file.interfaces';

export interface DownloadedFiles extends Partial<Files> {
  haveFiles: boolean;
  isFilesExistenceConfirmed: boolean;
}

export function useCreateAppchainFiles(): DownloadedFiles {
  const { config } = useChainConfig();
  const { paraId } = useCreateAppChainInfo();

  const { specRaw, genesisState, genesisWasm, haveFiles } =
    useCreateAppChainFiles();

  const {
    data,
    isFetched: isFetchedFiles,
    isSuccess,
    isFetchedUrls,
    shouldFetchFiles,
  } = useDownloadedFiles(paraId, config.key);

  return {
    isFilesExistenceConfirmed:
      (isFetchedUrls && !shouldFetchFiles) || isFetchedFiles || haveFiles,
    haveFiles: haveFiles || isSuccess,
    specRaw: specRaw || data?.specRaw,
    genesisState: genesisState || data?.genesisState,
    genesisWasm: genesisWasm || data?.genesisWasm,
  };
}
