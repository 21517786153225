import type { RelayChainConfig } from '@/config';
import { useParaLifecycles } from '@/hooks/polkadot/common/queries/useParaLifecycles';

export function useIsRegisteredInRelay(config: RelayChainConfig | undefined): {
  isRegistered: boolean;
  isLoading: boolean;
} {
  const data = useParaLifecycles(config);

  return {
    isLoading: data === undefined,
    isRegistered: !!data,
  };
}
