import { OffsiteLink } from '@/components/OffsiteLink';
import { SidebarHeader } from '@/components/Sidebar';
import {
  ManageAppchainTab,
  getTabInfo,
} from '@/components/pages/Dashboard/ManageAppchain/state/ManageAppchain.constants';
import { Center, Group, Stack, Text, useMantineTheme } from '@mantine/core';
import { IconCheck } from '@tabler/icons-react';

interface Props {
  version: number | undefined;
}

export function RuntimeUpToDate({ version }: Props) {
  const theme = useMantineTheme();

  return (
    <>
      <SidebarHeader
        title={getTabInfo(ManageAppchainTab.Runtime).title}
        goBack={ManageAppchainTab.Maintenance}
      />
      <Group py={'md'} px={'md'} gap={'xs'}>
        <Center
          bg={'tanssiTeal.9'}
          w={20}
          h={20}
          style={{ borderRadius: '100%' }}
        >
          <IconCheck size={14} stroke={1.5} color={'white'} />
        </Center>

        <Stack gap={0}>
          <Text size="sm">Your runtime is up to date!</Text>
          <OffsiteLink
            label={`Runtime ${version}`}
            c={'blue.7'}
            withIcon
            iconColor={theme.colors.blue[7]}
            iconSize={16}
            size={'sm'}
            url={`https://github.com/moondance-labs/tanssi/releases/tag/runtime-${version}`}
            td={'none'}
          />
        </Stack>
      </Group>
    </>
  );
}
