import { useIsExplorerRequested } from '@/components/AppchainCard/Alerts/state/Alerts.hooks';
import { SidebarActionButton } from '@/components/Sidebar/SidebarActionButton';
import { useManageAppchainState } from '@/components/pages/Dashboard/ManageAppchain/state';
import { EXPLORERS } from '@/config';
import { useApiNotifications } from '@/hooks/notifications/useApiNotifications';
import {
  useAppchainConfig,
  useAppchainEvmId,
  useAppchainName,
} from '@/hooks/polkadot/appchain';
import { trpc } from '@/utils/trpc';
import { useCallback } from 'react';

export interface Props {
  description: string;
  logo: string | undefined;
  isDisabled?: boolean;
}

export function SidebarBlockscoutButton({
  description,
  logo,
  isDisabled,
}: Props) {
  const { paraId, config } = useManageAppchainState();
  const appchainConfig = useAppchainConfig(paraId, config);
  const name = useAppchainName(paraId, config);
  const evmChainId = useAppchainEvmId(paraId, config);
  const { clientError } = useApiNotifications();

  const { isExplorerRequested, setIsExplorerRequested } =
    useIsExplorerRequested();

  const { isPending, mutateAsync } =
    trpc.messages.blockscoutRequested.useMutation();

  const onClick = useCallback(async () => {
    if (!paraId || !config || !appchainConfig || !evmChainId) {
      clientError('Missing required parameters', {
        paraId,
        config,
        appchainConfig,
        evmChainId,
      });
      return;
    }

    await mutateAsync({
      name,
      paraId,
      evmChainId,
      chainKey: config.key,
      tokenSymbol: appchainConfig.asset.originSymbol,
      rpc: appchainConfig.ws.replace('wss://', 'https://'),
    });

    setIsExplorerRequested(true);
  }, [
    name,
    paraId,
    evmChainId,
    config,
    appchainConfig,
    clientError,
    mutateAsync,
    setIsExplorerRequested,
  ]);

  return (
    <SidebarActionButton
      name={EXPLORERS.blockscout.name}
      description={description}
      logo={logo}
      isLoading={isPending}
      isDisabled={isExplorerRequested || isDisabled}
      isTooltipDisabled={!isExplorerRequested}
      tooltipLabel="Blockscout trial requested. Please wait while your request is processed and your instance is set up."
      onClick={onClick}
    >
      {isExplorerRequested ? 'Requested' : 'Request 2 Week Trial'}
    </SidebarActionButton>
  );
}
