import type { ChainConfig } from '@/config';
import { useCreateAppchainFiles } from '@/hooks/useCreateAppchainFiles';
import { chainSpecToContainerChainGenesisData } from '@/utils/tanssi';
import { useMemo } from 'react';
import { useApi } from './polkadot/useApi';

export function useTanssiGenesis(config: ChainConfig) {
  const api = useApi(config.ws);
  const { specRaw } = useCreateAppchainFiles();

  return useMemo(() => {
    if (!api || !specRaw) {
      return;
    }

    return chainSpecToContainerChainGenesisData(api, specRaw);
  }, [api, specRaw]);
}
