import {
  configAtom,
  paraIdAtom,
} from '@/components/pages/Dashboard/ManageAppchain/state/ManageAppchain.atoms';
import type { RuntimeUpgradesCompleted } from '@/components/pages/Dashboard/ManageAppchain/tabs/Runtime/state/RuntimeUpgrades.interfaces';
import { atom } from 'jotai';
import { atomWithStorage } from 'jotai/utils';

export const runtimeUpgradesStorageAtom = atomWithStorage<
  RuntimeUpgradesCompleted | undefined
>('runtime-upgrades-completed', undefined);

export const runtimeUpgradesAtom = atom(
  (get) => {
    const paraId = get(paraIdAtom);
    const config = get(configAtom);
    const upgrades = get(runtimeUpgradesStorageAtom);

    if (!paraId || !config || !upgrades) {
      return [];
    }

    return upgrades[config.key]?.[paraId] || [];
  },
  (
    get,
    set,
    upgrade: {
      from: number;
      to: number;
      txHash: string;
    },
  ) => {
    const paraId = get(paraIdAtom);
    const config = get(configAtom);
    const completed = get(runtimeUpgradesStorageAtom);

    if (!paraId || !config) {
      return;
    }

    if (!completed) {
      set(runtimeUpgradesStorageAtom, {
        [config.key]: {
          [paraId]: [upgrade],
        },
      });

      return;
    }

    set(runtimeUpgradesStorageAtom, {
      ...completed,
      [config.key]: {
        ...completed[config.key],
        [paraId]: [...completed[config.key][paraId], upgrade],
      },
    });
  },
);
