import { trpc } from '@/utils/trpc';

export function useAppchainRuntimeVersions(template: string | undefined) {
  return trpc.appchains.getRuntimeVersions.useQuery(
    {
      template: template || '',
    },
    {
      enabled: !!template,
      refetchOnWindowFocus: false,
    },
  );
}
