import { SidebarButton, SidebarStack } from '@/components/Sidebar';
import { useManageAppchain } from '@/components/pages/Dashboard/ManageAppchain/state';
import type { TabInfo } from '@/components/pages/Dashboard/ManageAppchain/state/ManageAppchain.constants';

interface Props {
  buttons: TabInfo[];
}

export function ManageAppchainSudoActionsSidebar({ buttons }: Props) {
  const { switchAppchainSidebarTab } = useManageAppchain();

  return (
    <SidebarStack>
      {buttons.map(({ title, description, tab, isDisabled }) => (
        <SidebarButton
          key={title}
          onClick={() => switchAppchainSidebarTab(tab)}
          title={title}
          description={description}
          isDisabled={isDisabled}
          isSudo
        />
      ))}
    </SidebarStack>
  );
}
