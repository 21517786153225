import { RegisterChainCard } from '@/components/pages/CreateAppChain/steps/RegisterChainStep/RegisterChainCard';
import {
  useIsRegisteredInRelay,
  useIsRegisteredInTanssi,
  useRegisterInRelay,
  useRegisterInTanssi,
} from '@/hooks/polkadot/common';
import { useChainConfig } from '@/hooks/useChainConfig';

export function RegisterInPolkadot() {
  const { config, relay } = useChainConfig();

  const {
    isLoading: isLoadingTanssi,
    isReadyToRegister: isReadyToRegisterInTanssi,
    onRegister: onRegisterInTanssi,
  } = useRegisterInTanssi(config);

  const {
    isLoading: isLoadingRelay,
    isReadyToRegister: isReadyToRegisterInRelay,
    onRegister: onRegisterInRelay,
  } = useRegisterInRelay(relay);

  const { isRegistered: isRegisteredInTanssi, isLoading: isMountingTanssi } =
    useIsRegisteredInTanssi(config);

  const { isRegistered: isRegisteredInRelay, isLoading: isMountingRelay } =
    useIsRegisteredInRelay(relay);

  const isMountedRelay = !isMountingRelay && isReadyToRegisterInRelay;
  const isMountedTanssi =
    isMountedRelay && !isMountingTanssi && isReadyToRegisterInTanssi;

  return (
    <>
      <RegisterChainCard
        position={1}
        chainName={'Relay'}
        isMounted={isMountedRelay}
        isLoading={isLoadingRelay}
        isDisabled={isRegisteredInRelay}
        isRegistered={isRegisteredInRelay}
        onClick={onRegisterInRelay}
      />
      <RegisterChainCard
        position={2}
        chainName={'Tanssi'}
        isMounted={isMountedTanssi}
        isLoading={isLoadingTanssi}
        isDisabled={
          !isRegisteredInRelay || isLoadingRelay || !isReadyToRegisterInTanssi
        }
        isRegistered={!!isRegisteredInTanssi}
        onClick={onRegisterInTanssi}
      />
    </>
  );
}
