import { useCreateAppChainFiles } from '@/components/pages/CreateAppChain/state/create.hooks';
import { Step } from '@/components/pages/CreateAppChain/state/create.interfaces';
import { useCreateAppchainLogger } from '@/hooks/register/useCreateAppchainLogger';
import { trpc } from '@/utils/trpc';
import { showNotification } from '@mantine/notifications';

export function useGenerateAllFiles() {
  const { logInfo } = useCreateAppchainLogger();
  const { setSpecRaw, setGenesisState, setGenesisWasm, haveFiles } =
    useCreateAppChainFiles();

  return trpc.files.generateAll.useMutation({
    onError() {
      showNotification({
        title: 'Something went wrong',
        message: 'Please try again later',
        color: 'red',
      });
    },
    onSuccess(data) {
      setSpecRaw(data.specRaw);
      setGenesisState(data.genesisState);
      setGenesisWasm(data.genesisWasm);

      logInfo(
        `All files were successfully ${haveFiles ? 're' : ''}generated.`,
        Step.Files,
      );
    },
  });
}
