import { SudoSidebarWrapper } from '@/components/pages/Dashboard/ManageAppchain/SudoSidebarWrapper';
import { useManageAppchainState } from '@/components/pages/Dashboard/ManageAppchain/state';
import { ManageAppchainTab } from '@/components/pages/Dashboard/ManageAppchain/state/ManageAppchain.constants';
import { RuntimeUpgradeRow } from '@/components/pages/Dashboard/ManageAppchain/tabs/Runtime/RuntimeUpgradeRow';
import {
  useAppchainRuntimeInfo,
  useAppchainSudo,
} from '@/hooks/polkadot/appchain';
import { Box, Group, Stack } from '@mantine/core';
import { Text } from '@mantine/core';

interface Props {
  closeOnBack?: boolean;
}

export function RuntimeUpgradeAvailable({ closeOnBack }: Props) {
  const { paraId, config } = useManageAppchainState();
  const runtime = useAppchainRuntimeInfo(paraId, config);
  const { hasSudoRights } = useAppchainSudo();

  if (!runtime) {
    return null;
  }

  return (
    <SudoSidebarWrapper
      tab={ManageAppchainTab.Runtime}
      goBack={closeOnBack ? undefined : ManageAppchainTab.Maintenance}
      goToOnProxyDisconnect={ManageAppchainTab.Maintenance}
    >
      <Stack
        gap={5}
        py={'md'}
        px={'md'}
        style={{
          borderBottom: '1px solid var(--mantine-color-gray-9)',
          borderTop: '1px solid var(--mantine-color-gray-9)',
        }}
      >
        <Group justify={'space-between'}>
          <Text c={'gray.6'} size={'sm'}>
            {'Your runtime build:'}
          </Text>
          {runtime && <Text size={'sm'}>{runtime.version.current}</Text>}
        </Group>
        <Group justify={'space-between'}>
          <Text c={'gray.6'} size={'sm'}>
            {'Latest runtime build:'}
          </Text>
          <Text size={'sm'}>{runtime?.version.latest || 0}</Text>
        </Group>
        <Group justify={'space-between'}>
          <Text c={'gray.6'} size={'sm'}>
            {'Upgrades needed:'}
          </Text>
          <Text size={'sm'}>{runtime?.version.behind || 0}</Text>
        </Group>
      </Stack>

      {hasSudoRights && (
        <Box py={'md'} px={'md'}>
          {runtime.upgrades.map(({ from, to, status, txHash }, index) => (
            <RuntimeUpgradeRow
              key={`${from}-${to}`}
              from={from}
              to={to}
              status={status}
              txHash={txHash}
              isLast={index === runtime.upgrades.length - 1}
            />
          ))}
        </Box>
      )}
    </SudoSidebarWrapper>
  );
}
