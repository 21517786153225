import type { AppRouter } from '@/server/router/router';
import { info, token } from '@/state/token';
import { isWindowDefined } from '@/utils/window';
import { type HTTPHeaders, httpBatchLink } from '@trpc/client';
import { createTRPCNext } from '@trpc/next';
import superjson from 'superjson';

function getBaseUrl(): string {
  if (isWindowDefined()) {
    return '';
  }

  return 'http://localhost:4000';
}

export function headers(): HTTPHeaders {
  if (!info.value) {
    return {};
  }

  return {
    Authorization: token.value,
  };
}

export const trpc = createTRPCNext<AppRouter>({
  config() {
    return {
      links: [
        httpBatchLink({
          headers,
          url: `${getBaseUrl()}/api/trpc`,
          transformer: superjson,
        }),
      ],
    };
  },
  ssr: false,
  transformer: superjson,
});
