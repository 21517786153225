import { useProxy } from '@/components/Proxy/state/proxy.hooks';
import { useSidebar } from '@/components/Sidebar/state/sidebar.hooks';
import type { SidebarTab } from '@/components/Sidebar/state/sidebar.interfaces';
import { useAppchainSudo } from '@/hooks/polkadot/appchain';
import { ellipsis } from '@/utils/address';
import { Group, Stack, Text } from '@mantine/core';
import { IconArrowLeft, IconLogout, IconX } from '@tabler/icons-react';
import classes from './SidebarHeader.module.css';

export interface Props {
  title: string;
  showProxy?: boolean;
  goToOnProxyDisconnect?: SidebarTab;
  goBack?: SidebarTab;
}

export function SidebarHeader({
  title,
  showProxy = false,
  goBack,
  goToOnProxyDisconnect,
}: Props) {
  const { close, setTab } = useSidebar();
  const { proxy, setProxy } = useProxy();
  const { isSudoAvailable } = useAppchainSudo();

  return (
    <Group
      h={45}
      px={'sm'}
      gap={5}
      align={'start'}
      justify="space-between"
      style={{ borderBottom: '1px solid var(--mantine-color-gray-9)' }}
      data-testid={'sidebar-title'}
    >
      <Group
        role={'button'}
        justify={'center'}
        align={'center'}
        w={35}
        c={'var(--mantine-color-gray-6)'}
        className={classes.icon}
        style={{ cursor: 'pointer' }}
        onClick={() => goBack && setTab(goBack)}
      >
        <IconArrowLeft
          display={goBack ? 'block' : 'none'}
          className={classes.icon}
          stroke={1.2}
          size={23}
        />
      </Group>

      <Stack gap={0}>
        <Text>{title}</Text>
        {showProxy && proxy && (
          <Group gap={5}>
            <Text size={'xs'} c={'gray.6'}>
              {'Proxy: '}
              {ellipsis(proxy.address, 4, 8)}
            </Text>
            <IconLogout
              className={classes.logout}
              size={15}
              onClick={() => {
                setProxy();

                if (!isSudoAvailable && goToOnProxyDisconnect) {
                  setTab(goToOnProxyDisconnect);
                }
              }}
              style={{ cursor: 'pointer' }}
            />
          </Group>
        )}
      </Stack>

      <Group
        role={'button'}
        justify={'center'}
        align={'center'}
        w={35}
        c={'var(--mantine-color-gray-6)'}
        className={classes.icon}
        style={{ cursor: 'pointer' }}
        onClick={close}
        data-testid={'sidebar-title-close'}
      >
        <IconX className={classes.icon} stroke={1.2} size={23} />
      </Group>
    </Group>
  );
}
