import { useProxy } from '@/components/Proxy/state/proxy.hooks';
import { useSidebar } from '@/components/Sidebar/state/sidebar.hooks';
import { useCreateAppchainReset } from '@/components/pages/CreateAppChain/state/create.hooks';
import { useManageAppchain } from '@/components/pages/Dashboard/ManageAppchain/state';
import { useChainConfig } from '@/hooks/useChainConfig';
import { useIsMounted } from '@/hooks/useIsMounted';
import { useAddress } from '@/state/token';
import { usePrevious } from '@mantine/hooks';
import { useRouter } from 'next/router';
import { useCallback, useEffect } from 'react';

export function useResetState(): void {
  const router = useRouter();
  const isMounted = useIsMounted();
  const previousIsMounted = usePrevious(isMounted);

  const address = useAddress();
  const previousAddress = usePrevious(address);

  const { config } = useChainConfig();
  const previousConfig = usePrevious(config);

  const { close } = useSidebar();
  const { setProxy } = useProxy();
  const { closeAppchainSidebar } = useManageAppchain();
  const resetCreateAppchain = useCreateAppchainReset();

  const clearSidebar = useCallback(() => {
    close();
    setProxy();
    closeAppchainSidebar();
  }, [close, closeAppchainSidebar, setProxy]);

  // Clear state when config changes
  useEffect(() => {
    if (previousConfig && config !== previousConfig) {
      clearSidebar();
    }
  }, [config, previousConfig, clearSidebar]);

  // Clear state when address changes
  useEffect(() => {
    if (!previousIsMounted) {
      return;
    }

    if (previousIsMounted && !address) {
      resetCreateAppchain(false);
    }

    if (previousAddress && address !== previousAddress) {
      clearSidebar();
      resetCreateAppchain(false);
    }
  }, [
    address,
    previousAddress,
    previousIsMounted,
    clearSidebar,
    resetCreateAppchain,
  ]);

  // Clear state on route change
  useEffect(() => {
    router.events.on('routeChangeComplete', clearSidebar);

    return () => {
      router.events.off('routeChangeComplete', clearSidebar);
    };
  }, [router, clearSidebar]);
}
