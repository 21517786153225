import { useProxiesState } from '@/components/pages/Proxies/state/proxies.hooks';
import type { AppchainConfig, ChainConfig } from '@/config';
import { useApi } from '@/hooks/polkadot/useApi';
import { useApiTransaction } from '../../useApiTransaction';

export function useDeleteProxy(
  config: ChainConfig | AppchainConfig,
  address: string,
) {
  const api = useApi(config.ws);
  const { setIsBlocked } = useProxiesState();

  const { isLoading, send } = useApiTransaction({
    tx: api?.tx.proxy.removeProxy,
    config,
    address,
    onLoading: setIsBlocked,
  });

  return {
    isLoading,
    send,
  };
}
