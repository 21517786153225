import { useFlag } from '@/hooks/flags/useFlag';

export function useIsBlockscoutTrialEnabled(): {
  isEnabled: boolean;
  isLoading: boolean;
} {
  const flag = useFlag('is-blockscout-trial-enabled');

  return {
    isEnabled: !!flag?.enabled,
    isLoading: !flag,
  };
}
