import { useRuntimeUpgradesCompleted } from '@/components/pages/Dashboard/ManageAppchain/tabs/Runtime/state/RuntimeUpgrades.hooks';
import type { ChainConfig } from '@/config';
import { useAppchainRuntimeVersions } from '@/hooks/github';
import { useApiNotifications } from '@/hooks/notifications/useApiNotifications';
import { useAppchainCurrentRuntime } from '@/hooks/polkadot/appchain';
import { useMemo } from 'react';

export enum UpgradeStatus {
  Completed = 'completed',
  Next = 'pending',
  Available = 'available',
}

interface RuntimeInfo {
  template: string;
  version: {
    current: number;
    next: number;
    latest: number;
    behind: number;
  };
  upgrades: {
    from: number;
    to: number;
    status: UpgradeStatus;
    txHash?: string;
  }[];
}

export function useAppchainRuntimeInfo(
  paraId: number | undefined,
  config: ChainConfig | undefined,
): RuntimeInfo | undefined {
  const current = useAppchainCurrentRuntime(paraId, config);
  const { upgraded } = useRuntimeUpgradesCompleted();
  const { clientError } = useApiNotifications();

  const { data: versions, isError } = useAppchainRuntimeVersions(
    current?.template,
  );

  return useMemo(() => {
    if (isError) {
      clientError('Failed to get runtime versions. Please try again later');
      return;
    }

    if (!current || !versions) {
      return;
    }

    const runtimesAhead =
      current.version < versions[versions.length - 1]
        ? versions.slice(versions.indexOf(current.version) + 1)
        : [];

    const upgradesAhead = runtimesAhead.map((to, index) => {
      const from = versions[versions.indexOf(to) - 1] || current?.version;

      return {
        from,
        to,
        status: index === 0 ? UpgradeStatus.Next : UpgradeStatus.Available,
      };
    });

    const upgradesCompleted = upgraded
      .filter((upgrade) => current.version !== upgrade.from)
      .map((upgrade) => ({
        ...upgrade,
        status: UpgradeStatus.Completed,
      }));

    return {
      template: current.template,
      version: {
        current: current.version,
        next: runtimesAhead[0],
        latest: versions[versions.length - 1],
        behind: runtimesAhead.length,
      },
      upgrades: [...upgradesCompleted, ...upgradesAhead],
    };
  }, [current, versions, upgraded, isError, clientError]);
}
