import {
  SidebarButton,
  SidebarHeader,
  SidebarStack,
} from '@/components/Sidebar';
import { useIsBlockscoutTrialEnabled } from '@/hooks/flags/useIsBlockscoutTrialEnabled';
import { useIsRuntimeUpgradeEnabledFlag } from '@/hooks/flags/useIsRuntimeUpgradeEnabledFlag';
import { useAppchainSudo } from '@/hooks/polkadot/appchain';
import { ScrollArea } from '@mantine/core';
import { useViewportSize } from '@mantine/hooks';
import { useManageAppchain } from '../state';
import {
  ManageAppchainTab,
  getTabInfo,
} from '../state/ManageAppchain.constants';

const buttons = [
  ManageAppchainTab.TokenManagement,
  ManageAppchainTab.BlockProduction,
  ManageAppchainTab.Xcm,
  ManageAppchainTab.Maintenance,
  ManageAppchainTab.Explorers,
  ManageAppchainTab.Indexers,
  ManageAppchainTab.Oracles,
  ManageAppchainTab.Bridges,
  ManageAppchainTab.AppchainStaking,
].map(getTabInfo);

export function Management() {
  const { switchAppchainSidebarTab } = useManageAppchain();
  const { height } = useViewportSize();
  const { isLoading, isSudoAvailable, isProxyAvailable } = useAppchainSudo();

  const { isEnabled: isRuntimeUpgradeEnabled } =
    useIsRuntimeUpgradeEnabledFlag();
  const { isEnabled: isBlockscoutTrialEnabled } = useIsBlockscoutTrialEnabled();

  return (
    <>
      <SidebarHeader title={getTabInfo(ManageAppchainTab.Management).title} />

      {!isLoading && (
        <ScrollArea.Autosize type={'never'} mah={height - 65}>
          <SidebarStack mt={isSudoAvailable && !isProxyAvailable ? 'sm' : 0}>
            {buttons.map(({ title, description, tab, isDisabled }) => {
              const isMaintenanceDisabled =
                tab === ManageAppchainTab.Maintenance &&
                !isRuntimeUpgradeEnabled;

              const isExplorerDisabled =
                tab === ManageAppchainTab.Explorers &&
                !isBlockscoutTrialEnabled;

              const isButtonDisabled =
                isDisabled || isMaintenanceDisabled || isExplorerDisabled;

              return (
                <SidebarButton
                  key={title}
                  onClick={() => switchAppchainSidebarTab(tab)}
                  title={title}
                  description={description}
                  isDisabled={isButtonDisabled}
                  badgeText={isButtonDisabled ? 'Coming soon' : undefined}
                />
              );
            })}
          </SidebarStack>
        </ScrollArea.Autosize>
      )}
    </>
  );
}
