import { useCreateAppChainInfo } from '@/components/pages/CreateAppChain/state/create.hooks';
import { Step } from '@/components/pages/CreateAppChain/state/create.interfaces';
import type { RelayChainConfig } from '@/config';
import { useWalletNotifications } from '@/hooks/notifications/useWalletNotifications';
import { useApiTransaction } from '@/hooks/polkadot';
import { useCreateAppchainLogger } from '@/hooks/register/useCreateAppchainLogger';
import { useState } from 'react';
import { useApi } from '../../useApi';

interface ReserveParaId {
  isLoading: boolean;
  send: VoidFunction;
}

export function useReserveParaId(
  config: RelayChainConfig | undefined,
): ReserveParaId {
  const [localParaId, setLocalParaId] = useState<number>();

  const api = useApi(config?.ws);
  const { logInfo } = useCreateAppchainLogger();
  const { setParaId } = useCreateAppChainInfo();
  const { transactionSuccess } = useWalletNotifications(config);

  const { send, isLoading } = useApiTransaction({
    tx: api?.tx.registrar.reserve,
    config,
    notify: { notifyOnSuccess: false },
    onEvents: (events) => {
      events.forEach(({ event: { data, method, section } }) => {
        if (section === 'registrar' && method === 'Reserved') {
          const parsedData = data.toHuman() as {
            paraId: string;
            who: string;
          };

          setLocalParaId(Number.parseInt(parsedData.paraId.replace(',', '')));
        }
      });
    },
    onSuccess: (txHash, blockHash) => {
      if (!localParaId) return;

      setParaId(localParaId);

      transactionSuccess({
        txHash,
        blockHash,
        title: 'Appchain ID successfully reserved',
        message: `Appchain ID with value of ${localParaId} was successfully reserved.`,
      });

      logInfo('Para ID successfully reserved.', Step.ParaID, {
        paraId: localParaId,
      });
    },
  });

  return {
    isLoading,
    send,
  };
}
