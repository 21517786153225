import {
  ethereumAddressSchema,
  substrateAddressSchema,
} from '@/schema/address.schema';
import { nameSchema, tokenSymbolSchema } from '@/schema/appchain.schema';
import {
  formNumberSchema,
  hexSchema,
  hexWithPrefixSchema,
  posIntSchema,
  uIntSchema,
} from '@/schema/number.schema';
import { z } from 'zod';

export const baseChainDataSchema = z.object({
  chainName: nameSchema,
  appchainUrl: z.union([z.string().length(0), z.string().url()]).optional(),
  x: z.union([z.string().length(0), z.string().min(3).max(20)]).optional(),
  tokenDecimals: formNumberSchema(posIntSchema.min(9).max(18)),
  tokenSymbol: tokenSymbolSchema,
  sudoBalance: formNumberSchema(posIntSchema.max(1e15)),
});

export const evmChainDataSchema = baseChainDataSchema.merge(
  z.object({
    isEthereum: z.literal(true),
    sudoAddress: ethereumAddressSchema,
    evmChainId: formNumberSchema(posIntSchema.max(99999999999)),
    baseFeePerGas: formNumberSchema(uIntSchema),
    elasticity: formNumberSchema(z.coerce.number().positive().max(100)),
    balances: z
      .object({
        id: z.string().uuid(),
        address: ethereumAddressSchema,
        balance: formNumberSchema(posIntSchema.max(1e15)),
      })
      .array(),
    precompiles: z
      .object({
        id: z.string().uuid(),
        address: ethereumAddressSchema,
        bytecode: hexSchema.or(hexWithPrefixSchema),
      })
      .array(),
    iconImage: z
      .object({
        type: z.string(),
        content: z.string(),
      })
      .optional(),
  }),
);

export const iconImage = z.object({
  type: z.string(),
  content: z.string(),
});

export const substrateChainDataSchema = baseChainDataSchema.merge(
  z.object({
    isEthereum: z.literal(false),
    ss58Format: formNumberSchema(posIntSchema),
    sudoAddress: substrateAddressSchema,
    balances: z
      .object({
        id: z.string().uuid(),
        address: substrateAddressSchema,
        balance: formNumberSchema(posIntSchema.max(1e15)),
      })
      .array(),
    iconImage: iconImage.optional(),
  }),
);
