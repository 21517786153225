import type { UnionToTuple } from 'type-fest';

export const APPCHAINS_LIMIT = 30;

export const DEMO_CHAIN_ID = 5678;
export const DEMO_APPCHAIN_PARA_ID = 3001;

export enum AppchainsEnum {
  All = 'All',
  EVM = 'EVM',
  Substrate = 'Substrate',
  Snaps = 'Snaps',
}

export enum ChainKey {
  Stagebox = 'stagebox',
  Dancebox = 'dancebox',
  Flashbox = 'flashbox',
  Stagelight = 'stagelight',
  Demo = 'demo',
  Local = 'local',
  LocalStagelight = 'localStagelight',
}
export type ChainKeyTuple = UnionToTuple<ChainKey>;
export const CHAIN_KEYS = Object.values(ChainKey) as ChainKeyTuple;

export const DEV_APPCHAINS = [
  ChainKey.Local,
  ChainKey.LocalStagelight,
  ChainKey.Stagebox,
  ChainKey.Stagelight,
];

export enum ForkKey {
  DanceboxStaking = 'dancebox-staking',
  DanceboxProxyStaking = 'dancebox-proxy-staking',
  DanceboxRuntime = 'dancebox-runtime',
  Stagelight = 'stagelight',
}
