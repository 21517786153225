import { useCreateAppChainInfo } from '@/components/pages/CreateAppChain/state/create.hooks';
import { useApiNotifications } from '@/hooks/notifications/useApiNotifications';
import { useChainConfig } from '@/hooks/useChainConfig';
import { trpc } from '@/utils/trpc';

export function useProcessPendingAppchain() {
  const { config } = useChainConfig();
  const { paraId } = useCreateAppChainInfo();
  const { clientError } = useApiNotifications();

  const { isPending, mutate } = trpc.appchains.register.useMutation({
    onError: (error) =>
      clientError('Unable to process registered appchain', { error }),
  });

  return {
    isPending,
    send: () => {
      if (!paraId) {
        clientError('Something went wrong. Para ID not found.');

        return;
      }

      mutate({ chainKey: config.key, paraId });
    },
  };
}
