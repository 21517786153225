import { useEvmChains } from '@/hooks/evm/useEvmChains';
import { connectors, demo } from '@/rainbowkit';
import { Center, Loader } from '@mantine/core';
import type { ReactNode } from 'react';
import { http, WagmiProvider, createConfig } from 'wagmi';

export interface Props {
  children: ReactNode;
}

export function WagmiConfigProvider({ children }: Props) {
  const { data: evmChains, isLoading, isError } = useEvmChains();

  if (isLoading) {
    <Center mih={'100vh'}>
      <Loader size={50} />
    </Center>;
  }

  const transports = (evmChains || []).reduce<
    Record<number, ReturnType<typeof http>>
  >(
    (acc, chain) => {
      acc[chain.id] = http();
      return acc;
    },
    {
      [demo.id]: http(),
    },
  );

  const wagmiConfig = createConfig({
    chains: !isError && evmChains ? [demo, ...evmChains] : [demo],
    transports,
    connectors,
    ssr: true,
  });

  return <WagmiProvider config={wagmiConfig}>{children}</WagmiProvider>;
}
