import { useManageAppchainState } from '@/components/pages/Dashboard/ManageAppchain/state';
import { RuntimeUpToDate } from '@/components/pages/Dashboard/ManageAppchain/tabs/Runtime/RuntimeUpToDate';
import { RuntimeUpgradeAvailable } from '@/components/pages/Dashboard/ManageAppchain/tabs/Runtime/RuntimeUpgradeAvailable';
import { useAppchainRuntimeInfo } from '@/hooks/polkadot/appchain';

interface Props {
  closeOnBack?: boolean;
}

export function RuntimeUpgrade({ closeOnBack }: Props) {
  const { paraId, config } = useManageAppchainState();
  const runtime = useAppchainRuntimeInfo(paraId, config);

  if (!runtime) {
    return null;
  }

  return runtime.version.behind ? (
    <RuntimeUpgradeAvailable closeOnBack={closeOnBack} />
  ) : (
    <RuntimeUpToDate version={runtime.version.current} />
  );
}
