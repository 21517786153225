import { TokenAmount } from '@/components/TokenAmount';
import { isDefined } from '@/utils/common';
import {
  Box,
  Center,
  Group,
  Skeleton,
  Text,
  useMantineTheme,
} from '@mantine/core';
import type { AssetAmount } from '@moonbeam-network/xcm-types';
import { IconCheck, IconX } from '@tabler/icons-react';

export interface Props {
  name: string;
  isRegistered: boolean;
  hasEnoughBalance: boolean | undefined;
  balance: AssetAmount | undefined;
  testId: string;
}

export function ChainBalanceCheck({
  name,
  isRegistered,
  hasEnoughBalance,
  balance,
  testId,
}: Props) {
  const theme = useMantineTheme();

  return (
    <Group gap={'sm'} wrap={'nowrap'}>
      {!isRegistered &&
        (isDefined(hasEnoughBalance) ? (
          <Center bg={'dark.6'} w={28} h={28} style={{ borderRadius: '100%' }}>
            {hasEnoughBalance ? (
              <IconCheck
                size={15}
                stroke={1.5}
                color={theme.colors.tanssiTeal[9]}
              />
            ) : (
              <IconX
                size={15}
                stroke={1.5}
                color={theme.colors.tanssiTeal[9]}
              />
            )}
          </Center>
        ) : (
          <Skeleton circle width={28} height={28} />
        ))}
      <Box>
        <Group gap={0}>
          <Text size={'lg'} mr={'xs'}>
            {name}
            {' balance: '}
          </Text>
          {balance ? (
            <TokenAmount size={'md'} amount={balance} data-testid={testId} />
          ) : (
            <Skeleton width={60} height={25} />
          )}
        </Group>
      </Box>
    </Group>
  );
}
