import { chains, dancebox, getRelay } from '@/config';
import { useChainKey } from '@/state/chain';

export function useChainConfig() {
  const key = useChainKey();
  const config = chains.get(key) || dancebox;
  const relay = getRelay(config);

  return { config, relay };
}
