import type { ChainConfig } from '@/config';
import { useApiHttp } from '@/hooks/polkadot/useApiHttp';
import type { Null, Option } from '@polkadot/types';
import { useQuery } from '@tanstack/react-query';

export function usePendingVerificationHttp(
  paraId: number | undefined,
  config: ChainConfig,
  enabled = false,
) {
  const api = useApiHttp(config.http);

  return useQuery({
    queryKey: ['pendingVerification', config.key, paraId || 0],
    queryFn: async () => {
      const res = (await api?.query[
        config.pallets.registrar
      ].pendingVerification(paraId)) as Option<Null>;

      return res.isSome;
    },
    enabled,
  });
}
