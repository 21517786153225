import { useIsProxyPageEnabledFlag } from '@/hooks/flags/useIsProxyPageEnabledFlag';
import { Anchor, Center, Group, useMantineTheme } from '@mantine/core';
import Link from 'next/link';
import { useRouter } from 'next/router';
import { TOOLS } from './Tools.constants';

interface Props {
  onClick?: () => void;
}

export function Tools({ onClick }: Props) {
  const { isEnabled: isProxyViewEnabled } = useIsProxyPageEnabledFlag();
  const router = useRouter();
  const theme = useMantineTheme();

  return (
    <Group gap={'xs'} w={'100%'} justify={'space-between'}>
      {TOOLS.filter(({ path }) => path !== '/proxy' || isProxyViewEnabled).map(
        ({ label, path }) => (
          <Anchor
            component={Link}
            key={label}
            href={path}
            onClick={onClick}
            w={'100%'}
            p={5}
            c={
              router.pathname.includes(path)
                ? theme.colors.tanssiTeal[9]
                : theme.colors.gray[6]
            }
            style={{
              textDecoration: 'none',
            }}
          >
            <Center>{label}</Center>
          </Anchor>
        ),
      )}
    </Group>
  );
}
