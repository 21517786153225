import { SidebarHeader, SidebarStack } from '@/components/Sidebar';
import { SidebarOracle } from '@/components/Sidebar/SidebarOracle';
import {
  ManageAppchainTab,
  getTabInfo,
} from '../state/ManageAppchain.constants';

const SIDEBAR_ORACLES = [
  {
    name: 'Band',
    description:
      'Band Protocol is a cross-chain data oracle platform that aggregates and connects real-world data and APIs to smart contracts.',
    url: 'https://forms.gle/nC7xJMBoA6HP5miF9',
    tutorial:
      'https://docs.tanssi.network/builders/toolkit/integrations/oracles/band/',
    logo: '/images/logo_band.svg',
  },
  {
    name: 'Acurast',
    description:
      'Acurast provides developers with a distributed, highly scalable arbitrary computation engine ready to be used for the most security sensitive tasks.',
    url: 'https://docs.acurast.com/',
    tutorial:
      'https://docs.tanssi.network/builders/toolkit/integrations/oracles/acurast/',
    logo: '/images/logo_acurast.svg',
  },
  {
    name: 'Phala',
    description:
      "Phat Contract Oracles, developed on the Phala Network, offer a novel solution to the oracle limitations by leveraging the network's secure, privacy-preserving infrastructure.",
    url: 'https://phala.network/phalanetwork101-Blockchain-Oracles',
    tutorial:
      'https://docs.tanssi.network/builders/toolkit/integrations/oracles/phala/',
    logo: '/images/logo_phala.svg',
  },
];

export function Oracles() {
  return (
    <>
      <SidebarHeader
        title={getTabInfo(ManageAppchainTab.Oracles).title}
        goBack={ManageAppchainTab.Management}
      />

      <SidebarStack>
        {SIDEBAR_ORACLES.map(({ logo, name, description, url, tutorial }) => (
          <SidebarOracle
            key={name}
            logo={logo}
            name={name}
            description={description}
            url={url}
            tutorial={tutorial}
          />
        ))}
      </SidebarStack>
    </>
  );
}
