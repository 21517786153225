import { useCreateAppChainInfo } from '@/components/pages/CreateAppChain/state/create.hooks';
import { type ChainConfig, type RelayChainConfig, getRelay } from '@/config';
import { useApiCall } from '@/hooks/polkadot';
import { useAllAppchains } from '@/hooks/polkadot/appchain';
import { useAddress } from '@/state/token';
import type { StorageKey } from '@polkadot/types';
import type { Option } from '@polkadot/types-codec';
import type { ParaInfo } from '@polkadot/types/interfaces';
import type { PolkadotRuntimeCommonParasRegistrarParaInfo } from '@polkadot/types/lookup';
import { useMemo } from 'react';
import { useApi } from '../../useApi';

export interface RegistrarParaId {
  deposit: number;
  manager: string;
  locked: boolean;
}
export interface ParaIdInfo {
  paraId: number;
  manager: string;
  locked: boolean;
}
export type ParaEntries = Array<[StorageKey, ParaInfo]>;

function transformRegistrarParaId(
  paraIdInfo: Option<PolkadotRuntimeCommonParasRegistrarParaInfo>,
): RegistrarParaId | undefined {
  if (paraIdInfo.isEmpty) {
    return undefined;
  }

  const unwrapped = paraIdInfo.unwrap();

  return {
    deposit: unwrapped.deposit.toNumber(),
    manager: unwrapped.manager.toHuman(),
    locked: Boolean(unwrapped.locked.toPrimitive()),
  };
}

function transform(entries: ParaEntries): ParaIdInfo[] {
  return entries.map(([key, info]) => {
    const paraId = Number((key.toHuman() as string[]).at(0)?.replace(',', ''));

    return {
      paraId,
      manager: info.manager.toString(),
      locked: info.locked.isTrue || false,
    };
  });
}

export function useReservedParaId(
  config: RelayChainConfig,
): RegistrarParaId | undefined {
  const api = useApi(config.ws);
  const { paraId } = useCreateAppChainInfo();

  return useApiCall(
    api?.query.registrar.paras,
    [paraId],
    transformRegistrarParaId,
    { shouldUnwrap: false },
  );
}

export function useReservedParaIds(
  config: RelayChainConfig | undefined,
): ParaIdInfo[] | undefined {
  const api = useApi(config?.ws);

  return useApiCall(api?.query.registrar.paras.entries, [], transform);
}

export function useUserReservedParaIds(
  config: RelayChainConfig | undefined,
): ParaIdInfo[] | undefined {
  const address = useAddress();
  const ids = useReservedParaIds(config);

  return useMemo(
    () => ids?.filter((id) => id.manager === address),
    [address, ids],
  );
}

export function useFreeUserParaIds(
  config: ChainConfig,
): ParaIdInfo[] | undefined {
  const relay = getRelay(config);
  const ids = useUserReservedParaIds(relay);
  const { data: appchains } = useAllAppchains(config);

  return useMemo(
    () =>
      ids
        ?.filter(
          (id) =>
            !id.locked &&
            !appchains?.find((appchain) => appchain.paraId === id.paraId),
        )
        .sort((a, b) => b.paraId - a.paraId),
    [appchains, ids],
  );
}
