import type { ChainConfig } from '@/config';
import { useApiCall } from '@/hooks/polkadot';
import type { Null, Option } from '@polkadot/types';
import { useApi } from '../../useApi';

export function usePendingVerification(
  paraId: number | undefined,
  config: ChainConfig,
): boolean | undefined {
  const api = useApi(config.ws);

  return useApiCall(
    api?.query[config.pallets.registrar].pendingVerification,
    [paraId],
    transform,
    { shouldUnwrap: false },
  );
}

function transform(res: Option<Null>): boolean {
  return res.isSome;
}
