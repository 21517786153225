import { useCreateAppChainFiles } from '@/components/pages/CreateAppChain/state/create.hooks';
import type { ChainKey } from '@/config';
import { useAddress } from '@/state/token';
import { trpc } from '@/utils/trpc';

const day = 24 * 60 * 60 * 1000;

export function useChainFilesUrls(
  paraId: number | undefined,
  chainKey: ChainKey,
) {
  const address = useAddress();
  const { haveFiles } = useCreateAppChainFiles();

  return trpc.files.getChainUrls.useQuery(
    {
      address: address || '',
      chainKey,
      paraId: paraId || 0,
    },
    {
      enabled: !haveFiles && !!address && !!paraId,
      staleTime: day,
      refetchOnMount: 'always',
    },
  );
}
