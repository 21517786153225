import { trpc } from '@/utils/trpc';

export function useEvmChains() {
  const { data, isLoading, isError } = trpc.appchains.getEvmAppchains.useQuery(
    undefined,
    {
      refetchOnWindowFocus: false,
    },
  );

  return { data, isLoading, isError };
}
