import { ActionButton } from '@/components/ActionButton';
import {
  useIsRegisteredInTanssi,
  useRegisterInTanssi,
} from '@/hooks/polkadot/common';
import { useChainConfig } from '@/hooks/useChainConfig';

export function RegisterInRestake() {
  const { config } = useChainConfig();

  const { isLoading, isReadyToRegister, onRegister } =
    useRegisterInTanssi(config);

  const { isRegistered: isRegisteredInTanssi, isLoading: isMountingTanssi } =
    useIsRegisteredInTanssi(config);

  return (
    <ActionButton
      isReady={!isMountingTanssi && isReadyToRegister}
      isLoading={isLoading}
      disabled={isRegisteredInTanssi}
      onClick={onRegister}
    >
      Register
    </ActionButton>
  );
}
