import { useFlag } from '@/hooks/flags/useFlag';

export function useIsAppchainViewEnabledFlag(): {
  isEnabled: boolean;
  isLoading: boolean;
} {
  const flag = useFlag('is-appchain-view-enabled');

  return {
    isEnabled: !!flag?.enabled,
    isLoading: !flag,
  };
}
