import type { Signer } from '@polkadot/api/types';
import type { InjectedAccountWithMeta } from '@polkadot/extension-inject/types';
import { hexToString, stringToHex } from '@polkadot/util';
import { parse, stringify } from 'superjson';
import type { TokenInfo } from './token.interfaces';

export function createTokenInfoFromAccount(
  account: InjectedAccountWithMeta,
): TokenInfo {
  return {
    address: account.address,
    source: account.meta.source,
    createdAt: new Date(),
  };
}

export interface DecodeTokenResult {
  info: TokenInfo;
  decoded: string;
}

export function decodeToken(encoded: string): DecodeTokenResult {
  const decoded = hexToString(encoded);

  return {
    decoded,
    info: parse(decoded),
  };
}

export function getInfoFromToken(token: string): TokenInfo {
  const { info } = decodeToken(token.split('.')[0]);

  return info;
}

export async function createToken(
  account: InjectedAccountWithMeta,
  signer?: Signer,
): Promise<string> {
  const json = stringify(createTokenInfoFromAccount(account));
  const hex = stringToHex(json);

  if (!signer?.signRaw) {
    throw new Error('Signer not found');
  }

  const { signature } = await signer.signRaw({
    data: hex,
    address: account.address,
    type: 'payload',
  });

  return `${hex}.${signature}`;
}
