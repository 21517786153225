import { getPolkadotHttpApi } from '@/utils/polkadot';
import { useQuery } from '@tanstack/react-query';

async function fetchProxyTypes(http: string) {
  const api = await getPolkadotHttpApi(http);

  if (!api) {
    return;
  }

  const field = api.tx.proxy.addProxy.meta.fields.find((field) => {
    return field.name.toString() === 'proxy_type';
  });
  if (!field) {
    return;
  }
  const siType = api.registry.lookup.getSiType(field.type);
  return siType.def.asVariant.variants.map((variant) =>
    variant.name.toString(),
  );
}

export function useProxyTypes(http?: string) {
  const {
    data: proxyTypes = [],
    isLoading,
    error,
  } = useQuery({
    queryKey: ['proxyTypes', http],
    queryFn: async () => {
      if (!http) {
        return;
      }

      const proxyTypes = await fetchProxyTypes(http);

      return proxyTypes;
    },
    enabled: !!http,
  });

  return { proxyTypes, isLoading, error };
}
