import type { ChainConfig } from '@/config';
import { useApi } from '@/hooks/polkadot';
import { useAppchainUrls } from '@/hooks/polkadot/appchain';
import { useRegisteredParaIds } from '@/hooks/polkadot/common';
import type { ApiPromise } from '@polkadot/api';

export function useAppchainApi(
  paraId: number | undefined,
  config: ChainConfig | undefined,
): ApiPromise | undefined {
  const registeredParaIds = useRegisteredParaIds(config);
  const { urls } = useAppchainUrls(paraId, config);

  const ws =
    paraId && registeredParaIds?.includes(paraId) && urls ? urls.ws : undefined;

  return useApi(ws);
}
