import { AddressInput } from '@/components/AddressInput';
import type {
  EvmFormData,
  SubstrateFormData,
} from '@/server/router/file/file.interfaces';
import { Center, type ComboboxStore } from '@mantine/core';
import type { UseFormReturnType } from '@mantine/form';
import type { InjectedAccountWithMeta } from '@polkadot/extension-inject/types';
import { IconChevronDown, IconChevronUp } from '@tabler/icons-react';

interface Props {
  isEthereum: boolean;
  value: string;
  form: UseFormReturnType<EvmFormData | SubstrateFormData>;
  combobox: ComboboxStore;
  accounts: InjectedAccountWithMeta[];
}

export function SudoAddressInput({
  isEthereum,
  value,
  form,
  combobox,
  accounts,
}: Props) {
  return (
    <AddressInput
      isEthereum={isEthereum}
      value={value}
      {...form.getInputProps('sudoAddress')}
      onChange={(event) => {
        form.setFieldValue('sudoAddress', event.currentTarget.value);
      }}
      onFocus={() => combobox.closeDropdown()}
      onBlur={() => combobox.closeDropdown()}
      rightSection={
        !!accounts?.length && (
          <Center
            p={4}
            bg={'dark.6'}
            style={{ borderRadius: 5 }}
            onClick={() =>
              !combobox.dropdownOpened
                ? combobox.openDropdown()
                : combobox.closeDropdown()
            }
          >
            {combobox.dropdownOpened ? (
              <IconChevronUp size={15} color="white" />
            ) : (
              <IconChevronDown size={15} color="white" />
            )}
          </Center>
        )
      }
    />
  );
}
