import { ActionButton } from '@/components/ActionButton';
import { SelectAppchain } from '@/components/SelectAppchain';
import { SidebarStack } from '@/components/Sidebar';
import {
  useHrmpInitOpenChannel,
  useInvalidateHrmpChannels,
  useOpenableChannels,
} from '@/hooks/polkadot/xcm';
import { useOpenableChannelsAppchains } from '@/hooks/useOpenableChannelsAppchains';
import type { PublicAppchain } from '@/shared/db/entities/appchains';
import { Skeleton, Text } from '@mantine/core';
import { useState } from 'react';
import { SudoSidebarWrapper } from '../SudoSidebarWrapper';
import { useManageAppchainState } from '../state';
import { ManageAppchainTab } from '../state/ManageAppchain.constants';

export function XcmHrmpChannels() {
  const { paraId, config, relay } = useManageAppchainState();
  const [selected, setSelected] = useState<PublicAppchain | undefined>(
    undefined,
  );
  const paraIds = useOpenableChannels(paraId, relay);
  const invalidate = useInvalidateHrmpChannels();
  const { isLoading, send } = useHrmpInitOpenChannel(paraId, config, {
    onSuccess: () => {
      invalidate();
      setSelected(undefined);
    },
  });

  const { appchains, isLoading: isAppchainsLoading } =
    useOpenableChannelsAppchains(config?.key, paraIds);

  return (
    <SudoSidebarWrapper
      tab={ManageAppchainTab.XcmHrmpChannels}
      goBack={ManageAppchainTab.Xcm}
      goToOnProxyDisconnect={ManageAppchainTab.XcmHrmpChannels}
    >
      <SidebarStack>
        <Text>{'Appchain'}</Text>
        {!!appchains && !!config && !isAppchainsLoading ? (
          <SelectAppchain
            selectedAppchain={selected}
            appchains={appchains}
            onSelect={(appchain) => setSelected(appchain)}
          />
        ) : (
          <Skeleton height={50} />
        )}
        <ActionButton
          mt={'xl'}
          withArrow={false}
          isLoading={isLoading}
          disabled={!selected}
          fullWidth
          onClick={() => selected && send(selected.paraId)}
        >
          {'Request channel'}
        </ActionButton>
      </SidebarStack>
    </SudoSidebarWrapper>
  );
}
