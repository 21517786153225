import { RegisterInPolkadot } from '@/components/pages/CreateAppChain/steps/RegisterChainStep/RegisterInPolkadot';
import { RegisterInRestake } from '@/components/pages/CreateAppChain/steps/RegisterChainStep/RegisterInRestake';
import { StepWrapper } from '@/components/pages/CreateAppChain/steps/StepWrapper';
import { useIsRegisteredInTanssi } from '@/hooks/polkadot/common';
import { useChainConfig } from '@/hooks/useChainConfig';
import { Group, Text } from '@mantine/core';

export function RegisterChainStep() {
  const { config } = useChainConfig();

  const { isRegistered: isRegisteredInTanssi, isLoading: isMountingTanssi } =
    useIsRegisteredInTanssi(config);

  return (
    <StepWrapper>
      <StepWrapper.Title>
        {!isMountingTanssi && isRegisteredInTanssi
          ? 'You Have Registered Your Appchain Successfully'
          : 'Register Your Appchain'}
      </StepWrapper.Title>
      <StepWrapper.Card>
        <Text size={'lg'} ta={'center'}>
          {`Register your appchain with Tanssi to become an appchain. ${
            config.isSymbioticChain ? 'The' : 'Each'
          } transaction may take up to several minutes.`}
        </Text>
        <Group
          mih={config.isSymbioticChain ? 'auto' : 270}
          mt={'xl'}
          justify={'center'}
          align={'center'}
          gap={'xl'}
        >
          {config.isSymbioticChain ? (
            <RegisterInRestake />
          ) : (
            <RegisterInPolkadot />
          )}
        </Group>
      </StepWrapper.Card>
    </StepWrapper>
  );
}
