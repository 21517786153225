import {
  useCreateAppChainInfo,
  useCreateAppchainReset,
} from '@/components/pages/CreateAppChain/state/create.hooks';
import { Step } from '@/components/pages/CreateAppChain/state/create.interfaces';
import { useProcessPendingAppchain } from '@/hooks/api/useProcessPendingAppchain';
import { usePendingVerificationHttp } from '@/hooks/polkadot/common/queries/usePendingVerificationHttp';
import { useCreateAppchainLogger } from '@/hooks/register/useCreateAppchainLogger';
import { useChainConfig } from '@/hooks/useChainConfig';
import { useGoHome } from '@/hooks/useGoHome';
import { enableProgressBar } from '@/utils/loading';
import { trpc } from '@/utils/trpc';
import { useQueryClient } from '@tanstack/react-query';
import { useCallback } from 'react';

export function useAppchainOnRegistered() {
  const { config } = useChainConfig();
  const trpcUtils = trpc.useUtils();
  const queryClient = useQueryClient();
  const { paraId } = useCreateAppChainInfo();
  const { replaceHome } = useGoHome();
  const resetCreateAppchain = useCreateAppchainReset();
  const { logInfo } = useCreateAppchainLogger();
  const { send: processAppchain } = useProcessPendingAppchain();
  const { refetch: isAppchainPending } = usePendingVerificationHttp(
    paraId,
    config,
  );

  return useCallback(async () => {
    enableProgressBar(false);

    await replaceHome();
    await trpcUtils.files.getChainUrls.invalidate();
    await queryClient.invalidateQueries({
      queryKey: ['userAppchains', config.key],
    });

    const isPending = await isAppchainPending();

    if (isPending) {
      processAppchain();
      resetCreateAppchain();
    }

    logInfo('On registered action executed', Step.Register, { isPending });
  }, [
    config.key,
    queryClient,
    logInfo,
    replaceHome,
    processAppchain,
    isAppchainPending,
    resetCreateAppchain,
    trpcUtils.files.getChainUrls.invalidate,
  ]);
}
