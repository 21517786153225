import type { ParsedUrlQuery } from 'node:querystring';
import { CHAIN_KEYS, type ChainKey } from '@/config';

export function getChainKeyFromQuery({
  chainKey,
}: ParsedUrlQuery): string | undefined {
  return Array.isArray(chainKey) ? chainKey.at(0) : chainKey;
}

export function isChainKeyValid(param: string | undefined): param is ChainKey {
  return !!param && CHAIN_KEYS.includes(param as ChainKey);
}
