import { posIntSchema } from '@/schema/number.schema';
import { z } from 'zod';

export const tokenSymbolSchema = z.string().regex(/^[a-zA-Z]{2,10}$/, {
  message: 'Invalid token symbol. Can contain only 2-10 letters.',
});
export const paraIdSchema = posIntSchema;
export const nameSchema = z.string().min(3).max(40);
export const urlSchema = z.union([z.string().length(0), z.string().url()]);
export const xSchema = z.union([
  z.string().length(0),
  z.string().min(3).max(20),
]);
