import { Center, Combobox, Group, Text, useMantineTheme } from '@mantine/core';
import type { InjectedAccountWithMeta } from '@polkadot/extension-inject/types';
import { IconEdit } from '@tabler/icons-react';
import { SudoAddressDropdownOption } from './SudoAddressDropdownOption';
import classes from './SudoEvmAddressEditTemplate.module.css';

interface Props {
  accounts: InjectedAccountWithMeta[];
  onClick: () => void;
}

export function SudoAddressDropdownOptions({ accounts, onClick }: Props) {
  const theme = useMantineTheme();

  return (
    <>
      {accounts?.map(({ address, meta }) => (
        <Combobox.Option
          key={address}
          value={address}
          my={4}
          px={5}
          className={classes.option}
          style={{ borderRadius: 5 }}
        >
          <SudoAddressDropdownOption
            key={address}
            address={address}
            meta={meta}
          />
        </Combobox.Option>
      ))}
      <Combobox.Option
        className={classes.option}
        value={''}
        py={'xs'}
        px={5}
        style={{ borderRadius: 5 }}
        onClick={onClick}
      >
        <Group wrap="nowrap" gap={7}>
          <Center
            bg={'dark.7'}
            pos={'relative'}
            h={24}
            w={24}
            style={{ borderRadius: '50%' }}
          >
            <IconEdit
              size={14}
              stroke={1.5}
              color={theme.colors.tanssiTeal[9]}
            />
          </Center>
          <Text size="sm">{'Enter custom address'}</Text>
        </Group>
      </Combobox.Option>
    </>
  );
}
