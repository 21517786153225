import { useManageAppchainState } from '@/components/pages/Dashboard/ManageAppchain/state';
import { useRuntimeUpgradesCompleted } from '@/components/pages/Dashboard/ManageAppchain/tabs/Runtime/state/RuntimeUpgrades.hooks';
import { useAppchainNextRuntimeWasm } from '@/hooks/github';
import { useApiNotifications } from '@/hooks/notifications/useApiNotifications';
import { useWalletNotifications } from '@/hooks/notifications/useWalletNotifications';
import {
  useAppchainApi,
  useAppchainConfig,
  useAppchainSudo,
} from '@/hooks/polkadot/appchain';
import { useApiTransaction } from '@/hooks/polkadot/useApiTransaction';
import { useEventTrigger } from '@/hooks/polkadot/useEventTrigger';
import { useState } from 'react';

export function useAppchainUpgradeRuntime(from: number, to: number) {
  const [isLoading, setIsLoading] = useState(false);

  const { sudoKey } = useAppchainSudo();
  const { paraId, config } = useManageAppchainState();
  const api = useAppchainApi(paraId, config);
  const appchainConfig = useAppchainConfig(paraId, config);
  const { isFetching, refetch } = useAppchainNextRuntimeWasm();
  const { clientError } = useApiNotifications();
  const { transactionSuccess } = useWalletNotifications(appchainConfig);
  const { setUpgraded } = useRuntimeUpgradesCompleted();

  const { isLoading: isLoadingTx, send } = useApiTransaction({
    tx: api?.tx.sudo.sudo,
    config: appchainConfig,
    address: sudoKey,
    onSuccess: (txHash, blockHash) => {
      transactionSuccess({
        txHash,
        blockHash,
        title: 'Runtime has been upgraded!',
        message: 'Please wait until the runtime is fully synchronized.',
      });
      setUpgraded({ from, to, txHash });
    },
    onError: () => {
      setIsLoading(false);
    },
    notify: { notifyOnSuccess: false, notifyOnError: true },
  });

  useEventTrigger({
    api,
    events: [api?.events?.migrations?.RuntimeUpgradeCompleted],
    onTrigger: () => {
      setIsLoading(false);
    },
  });

  return {
    isLoading: isFetching || isLoadingTx || isLoading,
    send: async () => {
      setIsLoading(true);

      const { data: wasm, isError } = await refetch();

      if (isError) {
        clientError('Unable to fetch runtime data. Please try again later.');
        setIsLoading(false);

        return;
      }

      if (api && wasm) {
        send(api.tx.system.setCode(wasm));
      }
    },
  };
}
