import type { BaseChainConfig } from '@/config';
import { useApi, useApiCall } from '@/hooks/polkadot';
import type { Vec, u128 } from '@polkadot/types-codec';
import type { ITuple } from '@polkadot/types-codec/types';
import type { PalletProxyProxyDefinition } from '@polkadot/types/lookup';

export type ProxyType = 'Any' | 'NonTransfer' | 'Governance' | 'Staking';

export type AccountProxy<Type extends ProxyType | string = ProxyType> = {
  delegate: string;
  proxyType: Type;
  delay: number;
};

export function useProxies(
  address: string | undefined,
  config?: BaseChainConfig,
): AccountProxy[] | undefined {
  const api = useApi(config?.ws);

  const proxies = useApiCall(
    api?.query.proxy?.proxies,
    [address],
    transformProxies,
  );
  return proxies;
}

export function transformProxies(
  proxies: ITuple<[Vec<PalletProxyProxyDefinition>, u128]>,
): AccountProxy[] {
  return proxies[0].map(({ delegate, proxyType, delay }) => ({
    delegate: delegate.toString(),
    proxyType: proxyType.type as ProxyType,
    delay: delay.toNumber(),
  }));
}
