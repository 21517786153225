import { OffsiteLink } from '@/components/OffsiteLink';
import { Box, Center, Group, Text } from '@mantine/core';
import NextImage from 'next/image';
import classes from './SidebarOracle.module.css';

export interface SidebarOracleProps {
  name: string;
  description: string;
  logo: string;
  url: string;
  tutorial?: string;
  isDisabled?: boolean;
}

export function SidebarOracle({
  name,
  description,
  logo,
  isDisabled,
  url,
  tutorial,
}: SidebarOracleProps) {
  return (
    <Box py={10} px={'md'} className={classes.oracleBox}>
      <Group align={'center'} gap={'xs'}>
        {logo && (
          <Center w={25}>
            <NextImage alt={`${name} logo`} src={logo} width={22} height={22} />
          </Center>
        )}
        <Text size={'lg'} opacity={isDisabled ? 0.5 : 1}>
          {name}
        </Text>
      </Group>
      <Group
        mt={3}
        justify={'space-between'}
        align={'end'}
        wrap={'nowrap'}
        opacity={isDisabled ? 0.5 : 1}
      >
        <Text size={'xs'} c={'gray.6'}>
          {description}
        </Text>
      </Group>
      <Group mt={5} justify={'space-around'}>
        <OffsiteLink
          py={10}
          px={'md'}
          url={url}
          isWrapper
          withConfirm={true}
          withIcon={false}
          className={classes.button}
          anchorColor={'white'}
        >
          {`Visit ${name}`}
        </OffsiteLink>
        {tutorial && (
          <OffsiteLink
            py={10}
            px={'md'}
            url={tutorial}
            isWrapper
            withIcon={false}
            className={classes.button}
            anchorColor={'white'}
          >
            See tutorial
          </OffsiteLink>
        )}
      </Group>
    </Box>
  );
}
