import { runtimeUpgradesAtom } from '@/components/pages/Dashboard/ManageAppchain/tabs/Runtime/state/RuntimeUpgrades.atoms';
import { useAtom } from 'jotai';

export function useRuntimeUpgradesCompleted() {
  const [upgraded, setUpgraded] = useAtom(runtimeUpgradesAtom);

  return {
    upgraded,
    setUpgraded,
  };
}
