import {
  isExplorerRequestedAtom,
  isFreeCreditsAlertShownStorageAtom,
} from '@/components/AppchainCard/Alerts/state/Alerts.atoms';
import { useAtom } from 'jotai';

interface FreeCreditsAlert {
  isFreeCreditsAlertShown: boolean;
  setIsFreeCreditsAlertShown: (value: boolean) => void;
}

export function useFreeCreditsAlert(): FreeCreditsAlert {
  const [isFreeCreditsAlertShown, setIsFreeCreditsAlertShown] = useAtom(
    isFreeCreditsAlertShownStorageAtom,
  );

  return {
    isFreeCreditsAlertShown,
    setIsFreeCreditsAlertShown,
  };
}

interface ExplorerAlert {
  isExplorerRequested: boolean;
  setIsExplorerRequested: (value: boolean) => void;
}

export function useIsExplorerRequested(): ExplorerAlert {
  const [isExplorerRequested, setIsExplorerRequested] = useAtom(
    isExplorerRequestedAtom,
  );

  return {
    isExplorerRequested,
    setIsExplorerRequested,
  };
}
