import { useAtom } from 'jotai';
import { isBlockedAtom } from './proxies.atoms';

export function useProxiesState() {
  const [isBlocked, setIsBlocked] = useAtom(isBlockedAtom);

  return {
    isBlocked,
    setIsBlocked,
  };
}
