import { useCreateAppChainFiles } from '@/components/pages/CreateAppChain/state/create.hooks';
import { Step } from '@/components/pages/CreateAppChain/state/create.interfaces';
import { useCreateAppchainLogger } from '@/hooks/register/useCreateAppchainLogger';
import { trpc } from '@/utils/trpc';

export function useGenerateGenesis() {
  const { logInfo } = useCreateAppchainLogger();
  const { setGenesisState, setGenesisWasm, haveFiles } =
    useCreateAppChainFiles();

  return trpc.files.generateGenesis.useMutation({
    onSuccess(data) {
      setGenesisState(data.genesisState);
      setGenesisWasm(data.genesisWasm);

      logInfo(
        `Genesis files were successfully ${haveFiles ? 're' : ''}generated.`,
        Step.Files,
      );
    },
  });
}
